.checkbox-list .input-group {
    display: block !important;
    margin-left: 2% !important;
}

.text-input-half-width {
    width: 50% !important;
}

.file_hover li:not(.list-group-header):hover {
    cursor: pointer !important;
}

.file_hover li:not(.list-group-header):hover::after {
    //content: 'Κάνετε κλικ για να μεταφορτώσετε το Αρχείο';
    content: url('./icons/download_red_arrow.png');
    display: inline-block;
    //color:darkred;
    //font-size: 0.9em;
}
