$navbar-brand-width: 300px;
$navbar-height: 100px;
$navbar-brand-minimized-width: 160px;
$breadcrumb-active-color: #464E58;

//$breadcrumb-active-color: #464E58; 
.breadcrumb-item > a {
   color: #104F65;
}

.breadcrumb-item > a:hover {
   color: #104F65;
}

//bootstrap primary button color to be complied with WCAG
$primary: #0F4F66;

//bootstrap pager colors to be complied with WCAG
.page-link {
   color: #4F5963;
}

nav>ul.pagination>li.disabled>a.page-link {
   color: #49515B;
}

.badge.badge-danger,  .badge.bg-danger, 
.btn.btn-primary.btn-md.btn-danger.submit-fail,
.btn.btn-danger
{
   background-color: #A60808 !important;
}

.btn-outline-danger {
   color: #A60808 !important;
}

.btn-outline-danger:hover {
   color: white !important;
   background-color: #A60808 !important;
}

.badge.bg-success {
   background-color: #004200 !important;
}

.nav.nav-tabs.list-inline .nav-item.list-inline-item .nav-link {
   color: #464E58 !important;
}

.nav.nav-tabs .nav-item .nav-link {
   color: #464E58 !important;
}