// Variable overrides

//Navbar
$navbar-bg: #003476;
$navbar-color: white;
$sidebar-bg:  white;

// Sidebar

$sidebar-width:                       230px;
// $sidebar-padding:                     0 !default;
// $sidebar-minimized-width:             50px !default;
// $sidebar-minimized-height:            $sidebar-minimized-width !default;
// $sidebar-compact-width:               150px !default;
// $sidebar-compact-height:              $sidebar-compact-width !default;
// $sidebar-color:                       #fff !default;
// $sidebar-bg:                          $gray-800 !default;
// $sidebar-borders:                     none !default;
// $mobile-sidebar-width:                220px !default;

// Sidebar Navigation

// $sidebar-nav-color:                   #800808 ;
// $sidebar-nav-title-padding-y:         .75rem !default;
// $sidebar-nav-title-padding-x:         1rem !default;
$sidebar-nav-title-color:             navy;
// $sidebar-nav-link-padding-y:          .75rem !default;
// $sidebar-nav-link-padding-x:          1rem !default;
$sidebar-nav-link-color:              #414141;
// $sidebar-nav-link-bg:                 #003476;
// $sidebar-nav-link-icon-color:         $gray-600 !default;
// $sidebar-nav-link-borders:            0 !default;

$sidebar-nav-link-hover-color:        #414141;
$sidebar-nav-link-hover-bg:           white;
$sidebar-nav-link-hover-icon-color:   blue;
// $sidebar-nav-link-hover-borders:      0 !default;

$sidebar-nav-link-active-color:       #fff !default;
$sidebar-nav-link-active-bg:          #003476;
// $sidebar-nav-link-active-icon-color:  theme-color("primary") !default;
// $sidebar-nav-link-active-borders:     0 !default;

// $sidebar-nav-link-disabled-color:       darken(#fff, 30%) !default;
// $sidebar-nav-link-disabled-bg:          $sidebar-bg !default;
// $sidebar-nav-link-disabled-icon-color:  $sidebar-nav-link-icon-color !default;
// $sidebar-nav-link-disabled-borders:     0 !default;

// $sidebar-nav-dropdown-color:          #fff !default;
// $sidebar-nav-dropdown-bg:             rgba(0,0,0,.2) !default;
// $sidebar-nav-dropdown-borders:        0 !default;
// $sidebar-nav-dropdown-indicator-color:$gray-600 !default;
// $sidebar-nav-dropdown-indicator:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;
// $sidebar-nav-dropdown-indicator-hover-color:$sidebar-nav-link-hover-color;
// $sidebar-nav-dropdown-indicator-hover:str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-hover-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;
